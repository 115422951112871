<style scoped>
@import "~dual-listbox-vue/dist/dual-listbox.css";
</style>

<template>
    <b-modal
      id="etiquetteModal"
      size="xlg"
      hide-header
      hide-footer
      scrollable
      centered
      button-size="sm"
    >
      <div class="duallist-wrapper">
        <div class="card">
          <div class="card-header">
            <h5>
              Impression des etiquettes de prix
            </h5>
          </div>
          <div class="card-body p-0">
            <div class="row">
              <div class="col-6 bg-light">
                <h6 class="text-center my-2">Liste des tout les articles</h6>
              </div>
              <div class="col-6 bg-light">
                <h6 class="text-center my-2">Liste des articles à imprimer</h6>
              </div>
              <div class="col-12">
                <DualListBox
                    class="h-100"
                  :source="listarticles.source"
                  :destination="listarticles.destination"
                  label="designation"
                  @onChangeList="onChangeList"
                />
              </div>
            </div>
          </div>
          <div class="card-footer modal-footer">
            <b-button
              variant="secondary"
              class="btn rounded-0"
              @click="closeModal()"
              >Fermer</b-button
            >
            <b-button
              type="button"
              variant="primary"
              class="btn btn-primary rounded-0"
              @click="imprimerEtiquette('sm')"
              >Mini Etiquette</b-button
            >
            <b-button
              type="button"
              variant="primary"
              class="btn btn-primary rounded-0"
              @click="imprimerEtiquette()"
              >Moyen Etiquette</b-button
            >
          </div>

        </div>
      </div>

      <!-- <div class="dualListModal modal-footer">
          <b-button variant="secondary" @click="closeBlModal()">Fermer</b-button>
          <b-button type="button" variant="primary" @click="selectBl()"
            >Enregsitrer</b-button
          >
        </div> -->
    </b-modal>
</template>
<script>
import DualListBox from "dual-listbox-vue"
import axios from "axios"

export default {
    components:{
        DualListBox,
    },
    data (){
        return {
            listarticles : {
                source: [],
                destination: []
            },    
        };
    },
    methods:{
        openModal() {
            this.fetchArticle();
            this.$bvModal.show("etiquetteModal");
        },
        closeModal() {
            this.$bvModal.hide("etiquetteModal");
        },
        fetchArticle(){
            axios.get(this.BASE_URL+"/etiquette/fetcharticle").then(response => {
                this.listarticles.source = response.data;
            });
        },
        imprimerEtiquette(taille){
            var form = new FormData();
            this.listarticles.destination.forEach(article => {
                form.append("articles_id[]",article.id);
            });
            var lien = this.BASE_URL+"/etiquette/maxi";
            if(taille == "sm"){
                lien = this.BASE_URL+"/etiquette/mini";
            }
            axios.post(lien,form,{
                responseType: 'arraybuffer',
            }).then(res=> {
                console.log(res);
                let blob = new Blob([res.data], {type: 'arraybuffer'});
                let link = document.createElement('a');
                let objectURL = window.URL.createObjectURL(blob);
                link.href = objectURL;
                link.target = '_self';
                link.download = "etiquette de prix.pdf";
                (document.body || document.documentElement).appendChild(link);
                link.click();
                setTimeout(()=>{
                    window.URL.revokeObjectURL(objectURL);
                    link.remove();
                }, 100);
                // var myWindow = window.open("", "response", "resizable=yes");
                // myWindow.document.write(res.data);
            });
        },
        onChangeList: function({ source, destination }) {
            this.listarticles.source = source;
            this.listarticles.destination = destination;
            // console.log("Change list")
        },
        
    },
}
</script>